import React, { useEffect, useContext } from "react";
import { motion } from "framer-motion";
import UiContext from "../context/ui/UiContext";
// import "../css/contact.css"

function Contact(props) {
  const uicontext = useContext(UiContext);
  useEffect(() => {
    uicontext.setIsHome(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <motion.div
      initial={{ x: "-100vw" }}
      animate={{ x: 0 }}
      className="contactcontainer"
    >
      {/* <h1>Contact</h1> */}
      <div className="contact_container">
        <div className="contacttext">
          <h1>Contact</h1>
          <h2>Get in Touch with me.</h2>
          <div className="contacticons"><i className="fa-solid fa-envelope"></i><span>abidadhikari778@gmail.com</span></div>
          <div className="contacticons"></div>
        </div>
        <form className="contactform" onSubmit={(e)=>e.preventDefault()}>
          <div className="inputgroup">
            <label>Name</label>
            <input type="text"  required/>
          </div>
          <div className="inputgroup">
            <label>Email</label>
            <input type="email"  required/>
          </div>
          <div className="inputgroup">
            <label>Message</label>
            <textarea required></textarea>
          </div>
          <div className="inputgroup">
            <button type="submit">Send</button>
          </div>
        </form>
      </div>
      
    </motion.div>
  );
}

export default Contact;
