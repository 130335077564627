import React,{useState} from "react";
import UiContext from "./UiContext";

const UiState=(props)=> {
    const [isHome,setIsHome]=useState(false);
    return (
       <UiContext.Provider value={{isHome,setIsHome}}>
           {props.children}
       </UiContext.Provider>
    );
}

export default UiState;