import React,{useState} from "react";
import speechContext from "./speechContext";

const SpeechState=(props)=>{
    const [speech,setSpeech]=useState("Howdy");
    return(
        <speechContext.Provider value={{speech,setSpeech}}>
           {props.children}
        </speechContext.Provider>
    )
}
export default SpeechState;