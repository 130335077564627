import React, { useEffect, useContext } from "react";
import { motion } from "framer-motion";
import UiContext from "../context/ui/UiContext";
import "../css/services.css";

function Services(props) {
  const uicontext = useContext(UiContext);
  useEffect(() => {
    uicontext.setIsHome(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const servicesData = [
    {
      icon: "fa fa-code",
      title: "Web Design",
      desc: "Responsive Website design using vanilla HTML, CSS and JavaScript.",
    },

    {
      icon: "fa-solid fa-network-wired",
      title: "Backend API Development",
      desc: "Rest API's using Node and Express Js.",
    },
    {
      icon: "fa-brands fa-react",
      title: "Frontend Development",
      desc: "Complex site development using React/Next Js.",
    },
    {
      icon: "fa-solid fa-pen-nib",
      title: "Content Writing",
      desc: "Awesome Content filler for your Digital Products",
    },
    {
      icon: "fa fa-palette",
      title: "UI/UX Design",
      desc: "Eye Catching Responsive Design using figma.",
    },
    {
      icon: "fa fa-mobile-alt",
      title: "Mobile Application",
      desc: "Native Mobile app for both IOS and Android using React Native.",
    },
  ];
  return (
    <motion.div
      initial={{ x: "-100vw" }}
      animate={{ x: 0 }}
      className="servicescontainer"
    >
      <h1 className="servicesh1">Services</h1>

      <div className="services_container">
        {/* <motion.div
          className="servicebox"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.8, delay: 0.2 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0.5 },
          }}
        >
          <div className="serviceicon">
            <i class="fa fa-mobile-alt"></i>
          </div>
          <h4 className="servicetitle">Application/UI Design</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil
            velit ab itaque.
          </p>
        </motion.div> */}

        {servicesData.map((e) => {
          return (
            <>
              <motion.div
                className="servicebox"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.2 }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0.5 },
                }}
              >
                <div className="serviceicon">
                  <i class={e.icon}></i>
                </div>
                <h4 className="servicetitle">{e.title}</h4>
                <p>{e.desc}</p>
              </motion.div>
            </>
          );
        })}
      </div>
    </motion.div>
  );
}

export default Services;
