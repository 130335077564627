import React, { useEffect, useContext } from "react";
import { motion } from "framer-motion";
import UiContext from "../context/ui/UiContext";
import "../css/portfolio.css";

function Portfolio(props) {
  const uicontext = useContext(UiContext);
  //   const { scrollYProgress } = useViewportScroll();
  useEffect(() => {
    uicontext.setIsHome(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const portfolioData = [
    {
      title: "BabyID NPM package",
      btn: "Visit Package Site",
      desc: "Unique SLug ID generator for JavaScript Applications. Can be used to create video id for video sharing site, userid for unique author etc.",
      link: "https://www.npmjs.com/package/babyid",
      tools: "JavaScript (ES6)",
      src1: "/images/bip1.png",
      src2: "/images/bip2.png",
      code: "npm i babyid",
    },
    {
      title: "Coding Blog And Online Tools",
      btn: "Visit Site",
      desc: "AdhikariAbid- Coding Blog and Online Tools is a Blog and tools app created using NextJs/ReactJs for frontend and NodeJs and MySQL for backend. Entire Website is powered by ExpressJs REST API.",
      link: "https://www.adhikariabid.com.np",
      tools: "NextJs, CSS, NodeJs and ExpressJs , MySQL",
      src1: "/images/aap1.png",
      src2: "/images/aap2.png",
    },
    {
      title: "Business Landing Page",
      btn: "Visit Site",
      desc: "Demo Business Landing Page is Created using vanilla HTML and CSS . It demonstrate how basic landing page looks like.",
      link: "https://dreamy-yonath-8e5ed2.netlify.app/",
      tools: "HTML, CSS/SCSS, and JavaScript (ES6)",
      src1: "/images/blp1.png",
      src2: "/images/blp2.png",
    },
    {
      title: "Github Profile Readme Generator",
      btn: "Visit Site",
      desc: "One Page ReactJs app to create quick Readme Page for profile.",
      link: "https://abidadhikari.github.io/github-readme-generator-profile/",
      tools: "ReactJs, CSS/SCSS",
      src1: "/images/ghg1.png",
      src2: "/images/ghg2.png",
    },
  ];
  return (
    <>
      <motion.div
        initial={{ x: "200vw" }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5 }}
        className="portfoliocontainer"
      >
        <h1 className="portfolioh1">Projects</h1>

        <div className="portfolio_box_container">
          {portfolioData.map((e) => {
            return (
              <>
                <div className="projectbox">
                  <motion.div
                    className="projecttxt"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1, x: 0 },
                      hidden: { opacity: 0, x: -100 },
                    }}
                  >
                    <h2>{e.title}</h2>
                    <p>{e.desc}</p>
                    <span>{e.code ? <pre>{e.code}</pre> : ""}</span>
                    <p>
                      <b>Tools And Technology used :</b>
                      <br />
                      {e.tools}
                    </p>
                    {/* <div className="toolsandtech"></div> */}
                    <a href={e.link} target="_blank" rel="noreferrer">
                      <button className="pbtn">{e.btn}</button>
                    </a>
                  </motion.div>
                  <motion.div
                    className="projectimg"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    variants={{
                      visible: { opacity: 1, x: 0 },
                      hidden: { opacity: 0, x: 200 },
                    }}
                  >
                    <a href={e.link} target="_blank" rel="noreferrer">
                      <img
                        src={e.src1}
                        onMouseEnter={(element) =>
                          (element.target.src = `${e.src2}`)
                        }
                        onMouseLeave={(element) =>
                          (element.target.src = e.src1)
                        }
                        alt="abid adhikari project screenshot"
                      />
                    </a>
                  </motion.div>
                </div>
              </>
            );
          })}
        </div>
        <div className="vghrepos">
          <a
            href="https://github.com/abidadhikari?tab=repositories"
            target="_blank"
            rel="noreferrer"
          >
            View Github Repos
          </a>
        </div>
      </motion.div>
    </>
  );
}

export default Portfolio;
