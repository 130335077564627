import React from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import "./style.css";
import { Routes, Route } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import "./css/index.css";

function App() {
  return (
    <>
      <Header />
      {/* <h1>Abid Adhikari</h1> */}
      <Routes>
        <Route path="/" element={<Home />} exact></Route>
        <Route path="/about" element={<About />} exact></Route>
        <Route path="/portfolio" element={<Portfolio />} exact></Route>
        <Route path="/services" element={<Services />} exact></Route>
        <Route path="/contact" element={<Contact />} exact></Route>
      </Routes>
    </>
  );
}

export default App;
