import React, { useContext, useEffect, useState } from "react";
import linearbg from "../images/linearbg.svg";
import { Link, NavLink } from "react-router-dom";
import speechContext from "../context/speechContext";
import UiContext from "../context/ui/UiContext";
import { motion } from "framer-motion";
import "../css/header.css"

function Header(props) {
  const [night, setNight] = useState(false);
  const [mobilenav, setMobilenav] = useState(false);
  const speechcontext = useContext(speechContext);
  const uicontext = useContext(UiContext);
  useEffect(() => {
    let x = document.body.clientWidth;
    // console.log(x);
    if (x < 400) {
      document.body.classList.add("darkmode");
      setNight(true);
    } else {
    }
  }, []);
  useEffect(()=>{
   if(mobilenav){
     document.body.style.overflowY="hidden";
   }
   else{
    document.body.style.overflowY="scroll";
   }
  },[mobilenav] )
  const handleClick = () => {
    document.body.classList.toggle("darkmode");
    setNight((prev) => (prev ? false : true));
  };

  const handleBurgerClick = (e) => {
    document.getElementById("mobilenav").classList.toggle("activemobilenav");
    setMobilenav((prev) => (prev ? false : true));
  };

  const handleMobileNavClick = () => {
    handleBurgerClick();
  };

  const handleMouseOver = (e) => {
    speechcontext.setSpeech(e);
  };
  const closeNav = () => {
    setMobilenav(false);
    document.getElementById("mobilenav").classList.remove("activemobilenav");
  };
  return (
    <>
      {!uicontext.isHome ? (
        <>
        
       <motion.div 
       initial={{y:"-15vh"}}
       animate={{y:0}}
       transition={{delay:0.2,duration:0.5}}
       className="navcloth"
       >
       
       </motion.div>
       </>
      ) : (
        <div className="background_gradient">
          <img src={linearbg} alt="linear bg" />
        </div>
      )}
      
      <div className="main-head">
        <header>
          <Link
            to="/"
            className="logo"
            onClick={closeNav}
            onMouseEnter={() => handleMouseOver("I am Abid Adhikari")}
          >
            <motion.div
              initial={{ y: "-30vh" }}
              animate={{ y: 5 }}
              transition={{ delay: 0.3, duration: 1 }}
            >
              Abid Adhikari
            </motion.div>
          </Link>
          <nav>
            <ul>
              <li>
                <NavLink
                  to="/"
                  exact
                  onMouseEnter={() => handleMouseOver("Hello World")}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  exact
                  data
                  onMouseEnter={() => handleMouseOver("Get to know about me.")}
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/portfolio"
                  onMouseEnter={() => handleMouseOver("Check out my Portfolio")}
                >
                  Portfolio
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  onMouseEnter={() => handleMouseOver("Know what I offer.")}
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  onMouseEnter={() => handleMouseOver("Get in touch")}
                >
                  Contact
                </NavLink>
              </li>
              <li>
                <button
                  id="togglebtn"
                  onClick={handleClick}
                  onMouseEnter={() => handleMouseOver("Switch theme")}
                >
                  {night ? (
                    <i className="fa-solid fa-sun"></i>
                  ) : (
                    <i className="fa-solid fa-moon"></i>
                  )}
                  {/* <i className="fa-solid fa-moon"></i> */}
                </button>
              </li>
            </ul>
          </nav>
       

          <div className="burger pfixedtop">
            <button id="togglebtnmob" onClick={handleClick}>
              {night ? (
                <i className="fa-solid fa-sun"></i>
              ) : (
                <i className="fa-solid fa-moon"></i>
              )}
            </button>
            <div className="ham" onClick={handleBurgerClick}>
              {mobilenav ? (
                <i className="fa-solid fa-times"></i>
              ) : (
                <i className="fa-solid fa-bars"></i>
              )}
            </div>
          </div>
        </header>
      </div>
      <div className="mobilenav" id="mobilenav">
        <ul>
          <li>
            <NavLink onClick={handleMobileNavClick} to="/" exact>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handleMobileNavClick} to="/about" exact>
              About
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handleMobileNavClick} to="/portfolio">
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handleMobileNavClick} to="/services">
              Services
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handleMobileNavClick} to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
      
     
    </>
  );
}

export default Header;
