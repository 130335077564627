import React, { useContext, useEffect } from "react";
import speechContext from "../context/speechContext";
import animatedboy from "../images/animatedboy.svg";
import speechcloud from "../images/speechcloud.svg";
// import linearbg from "../images/linearbg.svg";
import { motion } from "framer-motion";
import UiContext from "../context/ui/UiContext";
import "../css/home.css";
function Home(props) {
  // const [speech,setSpeech]=useState("ok xa ta");
  const speechcontext = useContext(speechContext);
  const uicontext = useContext(UiContext);
  const handleMouseOver = (e) => {
    speechcontext.setSpeech(e);
  };
  useEffect(() => {
    uicontext.setIsHome(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="homecontainer">
        <div className="homediv">
          <div className="animatedboybox">
            <div className="speechcloud">
              <div className="speech">{speechcontext.speech}</div>
              <img src={speechcloud} alt="speech cloud" />
            </div>
            <img src={animatedboy} alt="animatedboy" />
          </div>
          <div className="hometxtbox">
            <motion.h1
              initial={{ x: "-100%" }}
              animate={{ x: 5 }}
              transition={{ delay: 0.4, duration: 0.8 }}
              onMouseEnter={() => handleMouseOver("I am JavaScript Developer")}
            >
              <div>JavaScript</div> <div>Developer</div>
            </motion.h1>
            <motion.p
              initial={{ x: "-150%" }}
              animate={{ x: 5 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              I am a full stack JavaScript developer with 2+ years of experience
              in front end web development and backend API development. I create
              awesome interactive digital solutions.
            </motion.p>
            <a href="./assets/resume.pdf" target="_blank">
              <motion.button
                initial={{ x: "-100vw" }}
                animate={{ x: 0 }}
                transition={{ delay: 1.2, duration: 0.5 }}
                onMouseEnter={() => handleMouseOver("Download my CV")}
              >
                Download CV
              </motion.button>
            </a>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}
          className="socialmedia"
        >
          <a
            href="https://www.facebook.com/abidadhikarics/"
            onMouseEnter={() => handleMouseOver("That is my Facebook Page")}
          >
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/abid.adhikari/"
            onMouseEnter={() => handleMouseOver("DM me in Instagram")}
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/abidadhikari/"
            onMouseEnter={() => handleMouseOver("My LinkedIn profile")}
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>
          <a
            href="https://github.com/abidadhikari/"
            onMouseEnter={() => handleMouseOver("Check my repos in Github")}
          >
            <i className="fa-brands fa-github"></i>
          </a>
          <a
            href="mailto:abidadhikari778@gmail.com"
            onMouseEnter={() => handleMouseOver("You Can mail me Directly.")}
          >
            <i className="fa-solid fa-envelope"></i>
          </a>
        </motion.div>
      </div>
    </>
  );
}

export default Home;
