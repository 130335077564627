import React, { useEffect, useContext } from "react";
import { motion } from "framer-motion";
import UiContext from "../context/ui/UiContext";
import "../css/about.css";

function About(props) {
  const uicontext = useContext(UiContext);
  useEffect(() => {
    function change() {
      uicontext.setIsHome(false);
    }
    change();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <div className="navcloth"></div> */}
      <motion.div
        className="aboutcontainer"
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ delay: 0.2, duration: 1 }}
      >
        <h1 className="aboutme__heading">About Me</h1>

        <section className="aboutme_section">
          <motion.div
            initial={{ x: "-100vw" }}
            animate={{ x: 0 }}
            transition={{ delay: 0.8, duration: 1 }}
            className="aboutme_img"
          >
            <img src="/images/animatedboy.png" alt="" srcset="" />
          </motion.div>
          <motion.div
            initial={{ x: "-100vw" }}
            animate={{ x: 0 }}
            transition={{ delay: 0.4, duration: 1 }}
            className="aboutme_txt"
          >
            <p>
              {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Assumenda dicta quibusdam iusto magni aliquam adipisci iure quam a
              repellendus ipsam in iste deserunt maxime animi veritatis non nam
              tempore quo, tempora officiis nulla eaque culpa eligendi. Maxime
              quidem consequatur amet minima, natus ipsa odio! Rerum nam
              recusandae perferendis quia aliquid? */}
              Hello World! My name is Abid Adhikari. I am a JavaScript Developer
              From Nepal. Apart from Web Development , I have keen interest in
              Robotics and AI. I am Currently studying BSC. Computer Science and
              Information technology at Tribhuvan University.
            </p>

            <div className="hori-line"></div>
            <div className="aboutme_icons">
              <a href="https://www.facebook.com/abidadhikarics/">
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/abid.adhikari/">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/in/abidadhikari/">
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://github.com/abidadhikari/">
                <i className="fa-brands fa-github"></i>
              </a>
              <a href="mailto:abidadhikari778@gmail.com">
                <i className="fa-solid fa-envelope"></i>
              </a>
            </div>
          </motion.div>
        </section>

        <div className="experiences">
          <h2>Experience</h2>
          <div className="experience">
            <div className="company_logo">
              <img
                src="/images/company-logo.png"
                alt="company logo maven solutions"
              />
            </div>
            <div className="experience_text">
              <h3>WEB DEVELOPER</h3>
              <h4>Maven Solutions · Internship</h4>
              <h5>April 2022 - July 2022</h5>
            </div>
          </div>
          <div className="experience">
            <div className="company_logo">
              <img
                src="/images/company-logo.png"
                alt="company logo maven solutions"
              />
            </div>
            <div className="experience_text">
              <h3>Frontend Developer</h3>
              <h4>Maven Solutions</h4>
              <h5>July 2022 - Current</h5>
            </div>
          </div>
        </div>

        <section className="about_skills">
          <h2>Skills</h2>
          <div className="about_skill_container">
            <img src="/images/js.png" alt="" />
            <img src="/images/mongo.png" alt="" />
            <img src="/images/mysql.png" alt="" />
            <img src="/images/node.png" alt="" />
            <img src="/images/python.png" alt="" />
            <img src="/images/react.png" alt="" />
            {/* <img src="/images/js.png" alt="" /> */}
          </div>
        </section>
      </motion.div>
    </>
  );
}

export default About;
